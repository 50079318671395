import { I18nLocaleObject, getI18nLocaleObject } from "../i18n";
import { InputSpecSelect, SomeInputSpec } from "../form-specs";

import { SelectOption } from "../form-specs/formSpec.types";
import namespaceList from "../i18n/namespaceList";

export interface BorderWidgetOptions {
    addBorder: boolean;
    borderSide: BorderSide;
    borderColor: string;
    borderWidth: number;
    addBorderRadius: boolean;
    borderRadius: string;
}

export enum BorderSide {
    AROUND = "around",
    TOP = "top",
    RIGHT = "right",
    LEFT = "left",
    BOTTOM = "bottom",
}

export const I18N_AROUND = getI18nLocaleObject(namespaceList.admin, "around");
export const I18N_TOP = getI18nLocaleObject(namespaceList.admin, "top");
export const I18N_RIGHT = getI18nLocaleObject(namespaceList.admin, "right");
export const I18N_LEFT = getI18nLocaleObject(namespaceList.admin, "left");
export const I18N_BOTTOM = getI18nLocaleObject(namespaceList.admin, "bottom");
export const I18N_BORDER_COLOR = getI18nLocaleObject(namespaceList.admin, "borderColor");
export const I18N_BORDER_WIDTH = getI18nLocaleObject(namespaceList.admin, "borderWidth");
export const I18N_ADD_BORDERRADIUS = getI18nLocaleObject(namespaceList.admin, "addBorderRadius");
export const I18N_BORDER_RADIUS = getI18nLocaleObject(namespaceList.admin, "chooseBorderRadius");

export async function getBorderOptions(): Promise<Array<SelectOption<string>>> {
    const options = [
        {
            value: BorderSide.AROUND,
            label: I18N_AROUND,
        },
        {
            value: BorderSide.TOP,
            label: I18N_TOP,
        },
        {
            value: BorderSide.RIGHT,
            label: I18N_RIGHT,
        },
        {
            value: BorderSide.BOTTOM,
            label: I18N_BOTTOM,
        },
        {
            value: BorderSide.LEFT,
            label: I18N_LEFT,
        },
    ];
    return options;
}

export function borderSideSelector<E, P extends keyof E>(variable: P, label?: I18nLocaleObject | string): InputSpecSelect<E, P> {
    return {
        variable,
        label: label || getI18nLocaleObject(namespaceList.admin, "borderSide"),
        type: "select",
        required: false,
        default: ({
            value: BorderSide.AROUND,
            label: I18N_AROUND,
        } as unknown) as E[P],
        optionList: () => getBorderOptions() as any,
        visible: (item: E) => (item as any).addBorder,
    };
}

export const borderColorSelector = (variable: string): SomeInputSpec<any, any> => ({
    variable,
    label: I18N_BORDER_COLOR,
    type: "dual-color",
    default: "default",
    visible: (item: any) => item.addBorder,
});

export const borderRadiusSelector = (variable: string): SomeInputSpec<any, any> => ({
    variable,
    label: I18N_BORDER_RADIUS,
    type: "range",
    default: 0,
    minimumNumberRange: 0,
    maximumNumberRange: 50,
    step: 1,
    visible: (item: any) => item.addBorder && item.addBorderRadius,
});

type BorderStyleSelectorType = "" | "borderSide" | "borderColor" | "borderWidth" | "addBorderRadius" | "borderRadius";

export const getBorderStyleSelector = (): Array<SomeInputSpec<any, BorderStyleSelectorType>> => [
    {
        variable: "addBorder",
        label: getI18nLocaleObject(namespaceList.admin, "addBorder"),
        type: "checkbox",
    },
    {
        ...borderSideSelector<any, any>("borderSide"),
    },
    {
        ...borderColorSelector("borderColor"),
    },
    {
        variable: "borderWidth",
        label: I18N_BORDER_WIDTH,
        type: "number",
        visible: (item: any) => item.addBorder,
    },
    {
        variable: "addBorderRadius",
        label: getI18nLocaleObject(namespaceList.admin, "addBorderRadius"),
        type: "checkbox",
        visible: (item: any) => item.addBorder,
    },
    {
        ...borderRadiusSelector("borderRadius"),
    },
];
