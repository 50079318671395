import * as MXTS from "@maxxton/cms-mxts-api";
import * as React from "react";

import { BorderSide, BorderWidgetOptions, getBorderStyleSelector } from "../../../utils/border.util";
import { Widget as CmsApiWidget, Form, FormApi, LocalizedOptions, WithId } from "@maxxton/cms-api";
import { FormSpec, InputSpec, SomeInputSpec, formSpec, localized, multiSelectStylePicker, pageSpec } from "../../../form-specs";
import { LocalizedFormOptions, LocalizedPageLink, SessionExpiredFallback } from "../userLogin";
import { LocalizedTitleOptions, getWidgetTitleOptions, titleStylingOptions } from "../../../components/widgetTitleAndLabel/localizedLableTitle.util";
import { PageWidgetSpec, Widget, reportWidgetRenderError } from "../../";
import { assetSpec, getMandatoryFields } from "../../../components/utils";
import { getI18nLocaleObject, getI18nLocaleString } from "../../../i18n";
import { isFormWidget, isPageWidget, parseApiWidget } from "../../widget";

import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { ContextUtil } from "../../../utils/context.util";
import { GuestInterface } from "./guestInterface";
import { MultiSelectOption } from "../../mxts/selectOption.types";
import { WidgetGroup } from "../../widget.enum";
import { autocompleteSiteSpec } from "../../../form-specs/models/autocompleteSite";
import { distributionChannelSpec } from "../../../utils/currency.util";
import { findMultiSelectStyleClassNames } from "../../../themes";
import { getLocalizedOptions } from "../../shared/revealer";
import { getReservationCategorySelectOptions } from "../../../utils/reservationCategories.util";
import namespaceList from "../../../i18n/namespaceList";

export interface WidgetOptions extends BorderWidgetOptions, LocalizedTitleOptions, SessionExpiredFallback, WithId {
    styleIds: string[];
    bookingModuleForm: string;
    assetType?: string;
    localized: Localized[];
    showSources: boolean;
    showConditionCheckBox: boolean;
    newsLetterCheckBox: boolean;
    siteId: string;
    pageId: string;
    file?: { document: MXTS.DocumentType };
    fillCustomerDetails?: boolean;
    forgotPasswordInterface: boolean;
    updatePasswordInterface: boolean;
    loginAfterUpdatePassword: boolean;
    updatePasswordAfterUserLoggedIn: boolean;
    localizedFormOptions?: LocalizedFormOptions[];
    // TODO: this field is only here because the userLoginWidget uses it. It would be better to move the login logic to the userLoginWidget instead of polluting the guestWidget with this.
    localizedPageLink?: LocalizedPageLink[];
    disableAutoFillCustomerDetails: boolean;
    enableDynamicTermsAndConditions?: boolean;
    useWithActivityPlanner?: boolean;
    freeActivityPageId?: string;
    loginSuccessTemplate?: string;
    isBookingFlowLoginRedirect?: boolean;
    useMinimalMandatoryFieldsOnly?: boolean;
    minimalMandatoryFields?: Array<MultiSelectOption<string>>;
    useExternalPaymentUrl?: boolean;
    useExternalRedirectUrl?: boolean;
}

export interface Localized {
    locale: string;
    file?: { document: MXTS.DocumentType };
    buttonText: string;
    newsLetterText: string;
    passwordUpdated?: string;
    loginPageLinkLabel?: string;
    reservationCategoryId?: number;
    externalRedirectUrl?: string;
    externalUrl?: string;
}

const TARGETS = ["guest-form"];

export const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "guestInterface",
    name: getI18nLocaleObject(namespaceList.admin, "guestInterfaceWidget"),
    pluralName: getI18nLocaleObject(namespaceList.admin, "guestInterfaceWidgets"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.admin, "general"),
                    properties: [
                        [
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "selectForm"),
                                variable: "bookingModuleForm",
                                type: "autocomplete",
                                refType: formSpec,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "siteRequiredField"),
                                variable: "siteId",
                                type: "autocomplete",
                                default: "",
                                refType: autocompleteSiteSpec,
                                visible: (options: WidgetOptions) => !options.useExternalPaymentUrl,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "paymentPage"),
                                variable: "pageId",
                                type: "autocomplete",
                                default: "",
                                refType: pageSpec,
                                dependsOnSiteSpec: "siteId",
                                visible: (options: WidgetOptions) => !options.useExternalPaymentUrl,
                            },
                            {
                                variable: "useExternalPaymentUrl",
                                label: getI18nLocaleObject(namespaceList.admin, "useExtPaymentUrl"),
                                type: "checkbox",
                                default: false,
                            },
                            localized({
                                variable: "localized",
                                tabContent: [
                                    {
                                        variable: "externalUrl",
                                        label: getI18nLocaleObject(namespaceList.admin, "externalUrl"),
                                        type: "text",
                                    },
                                ],
                                visible: (options: WidgetOptions) => !!options.useExternalPaymentUrl,
                            }),
                            {
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.admin, "useWithActivityPlanner"),
                                variable: "useWithActivityPlanner",
                            },
                            {
                                variable: "useMinimalMandatoryFieldsOnly",
                                label: getI18nLocaleObject(namespaceList.admin, "useMinimalMandatoryFieldsOnly"),
                                type: "checkbox",
                            },
                            {
                                variable: "minimalMandatoryFields",
                                label: getI18nLocaleObject(namespaceList.pluginForm, "minimalMandatoryFields"),
                                type: "multiselect",
                                optionList: async ({ item }: { item: WidgetOptions }) => {
                                    const fieldOptions: Array<MultiSelectOption<string>> = [];
                                    const form = item?.bookingModuleForm && (await FormApi.findById({ id: item.bookingModuleForm }));
                                    if (form) {
                                        const fieldsArray = MXTS.flatten(form.elements, (parent: any) => parent.children);
                                        fieldsArray.forEach((element) => {
                                            const { fieldId, dynamicFieldId } = element.options;
                                            const field = fieldId || dynamicFieldId;
                                            if (field) {
                                                fieldOptions.push({ value: field, text: field as string });
                                            }
                                        });
                                    }

                                    return fieldOptions;
                                },
                                visible: (options: WidgetOptions) => options.useMinimalMandatoryFieldsOnly && options.bookingModuleForm,
                            },
                            ...getWidgetTitleOptions<WidgetOptions>(),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "tabOptions"),
                    properties: [
                        [
                            {
                                variable: "showConditionCheckBox",
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.admin, "showTermsAndConditions"),
                                groupName: "termsAndConditionsGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.widgetGuestInterface, "termsAndConditionsGroupTitle"),
                                groupDescription: getI18nLocaleObject(namespaceList.widgetGuestInterface, "termsAndConditionsGroupDescription"),
                            },
                            // Spec explaination:
                            // variable name, visibility, variable media | undefined,
                            // variable file | undefined, localized boolean | empty
                            { ...assetSpec("assetType", "showConditionCheckBox", undefined, "file", true), groupName: "termsAndConditionsGroup" },
                            {
                                variable: "enableDynamicTermsAndConditions",
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.admin, "enableDynamicTermsAndConditions"),
                                visible: (options: WidgetOptions) => options.showConditionCheckBox,
                                groupName: "termsAndConditionsGroup",
                            },
                        ],
                        [
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "receiveEmailNewsletter"),
                                variable: "newsLetterCheckBox",
                                type: "checkbox",
                                groupName: "newsLetterCheckBoxGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.widgetGuestInterface, "newsLetterGroupTitle"),
                            },
                            {
                                type: "paragraph",
                                label: getI18nLocaleObject(namespaceList.admin, "defaultEmailText"),
                                visible: (options: WidgetOptions) => options.newsLetterCheckBox,
                                groupName: "newsLetterCheckBoxGroup",
                            },
                            localized({
                                variable: "localized",
                                groupName: "newsLetterCheckBoxGroup",
                                tabContent: [
                                    {
                                        variable: "newsLetterText",
                                        label: getI18nLocaleObject(namespaceList.admin, "changeNewsLetterText"),
                                        type: "text",
                                    },
                                ],
                                visible: (options: WidgetOptions) => options.newsLetterCheckBox,
                            }),
                        ],
                        [
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "disableAutoFillCustomerDetails"),
                                variable: "disableAutoFillCustomerDetails",
                                type: "checkbox",
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "showSources"),
                                variable: "showSources",
                                type: "checkbox",
                            },
                            localized({
                                variable: "localized",
                                label: getI18nLocaleObject(namespaceList.admin, "bookAsCompanyFlowConfiguration"),
                                tabContent: [
                                    { ...distributionChannelSpec },
                                    {
                                        variable: "reservationCategoryId",
                                        label: getI18nLocaleObject(namespaceList.admin, "reservationCategoryId"),
                                        type: "autocomplete" as const,
                                        isClearable: false,
                                        options: async (item: any, spec: any, root: any, tabLocale: string) => getReservationCategorySelectOptions(item, tabLocale),
                                        placeholder: getI18nLocaleObject(namespaceList.admin, "selectReservationCategoryId"),
                                        visible: (localizedOptions?: LocalizedOptions) => !!localizedOptions?.distributionChannelId,
                                    },
                                ],
                            }),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "styling"),
                    properties: [[multiSelectStylePicker("styleIds", TARGETS), ...getBorderStyleSelector(), ...titleStylingOptions<WidgetOptions>()]],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetActivityPlanner, "activityPlannerWidgetOptions"),
                    properties: [
                        [
                            {
                                label: getI18nLocaleObject(namespaceList.widgetActivityPlanner, "freeActivityResultPage"),
                                variable: "freeActivityPageId",
                                type: "autocomplete",
                                default: "",
                                refType: pageSpec,
                                dependsOnSiteSpec: "siteId",
                                visible: (options: any) => !options.useExternalRedirectUrl,
                            },
                            {
                                variable: "useExternalRedirectUrl",
                                label: getI18nLocaleObject(namespaceList.widgetGuestInterface, "externalUrlForFreeActivies"),
                                type: "checkbox",
                                default: false,
                            },
                            localized({
                                variable: "localized",
                                tabContent: [
                                    {
                                        variable: "externalRedirectUrl",
                                        label: getI18nLocaleObject(namespaceList.admin, "externalUrl"),
                                        type: "text",
                                        default: "",
                                    },
                                ],
                                visible: (options: WidgetOptions) => !!options.useExternalRedirectUrl,
                            }),
                        ],
                    ],
                    visible: (options: WidgetOptions) => !!options.useWithActivityPlanner,
                },
            ],
        },
    ],
};

export const guest: PageWidgetSpec<WidgetOptions> = {
    id: "guestWidget",
    type: "page",
    widgetGroup: WidgetGroup ? WidgetGroup.BOOKINGS_MODULE : 4,
    name: getI18nLocaleObject(namespaceList.admin, "guestWidget"),
    description: getI18nLocaleObject(namespaceList.admin, "guestWidgetDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): Omit<WidgetOptions, "_id"> => ({
        bookingModuleForm: "",
        assetType: "media",
        localized: [],
        showSources: false,
        showConditionCheckBox: false,
        newsLetterCheckBox: false,
        siteId: "",
        pageId: "",
        addBorder: false,
        addBorderRadius: false,
        borderSide: BorderSide.AROUND,
        borderColor: "default",
        borderWidth: 0,
        borderRadius: "",
        forgotPasswordInterface: false,
        updatePasswordInterface: false,
        loginAfterUpdatePassword: false,
        updatePasswordAfterUserLoggedIn: false,
        styleIds: [],
        disableAutoFillCustomerDetails: false,
    }),
    async render(widget: Widget<WidgetOptions>, context: CMSProvidedProperties) {
        const dynamicFormSpec = await getFormField(widget.options.bookingModuleForm, context);
        const formFields: string[] = [];
        const { styleIds } = widget.options;
        const className = findMultiSelectStyleClassNames(context.theme, TARGETS, styleIds);
        dynamicFormSpec.properties.forEach((formfield: any) => {
            if (formfield.children) {
                formfield.children.forEach((child: any) => {
                    formFields.push(child.variable);
                });
            } else {
                formFields.push(formfield.variable);
            }
        });
        const distributionChannelId = context.distributionChannelId;
        const mandatoryFields = await getMandatoryFields(context, distributionChannelId);
        const missingFields: string[] = [];
        const localeId = context.currentLocale.locale;
        const localContent = widget.options.localized ? widget.options.localized.find((lc) => lc.locale === localeId) : undefined;
        const languageIndex = mandatoryFields.indexOf("language");
        mandatoryFields.splice(languageIndex, 1);
        const mandatoryFieldsToUse = widget.options?.useMinimalMandatoryFieldsOnly ? widget.options?.minimalMandatoryFields?.map((field) => field.value) : mandatoryFields;
        const areAllMandatoyAdded =
            mandatoryFieldsToUse &&
            mandatoryFieldsToUse.every((field: any) => {
                if (formFields.indexOf(field) > -1) {
                    return true;
                }
                missingFields.push(field);
                return false;
            });
        if (!areAllMandatoyAdded && !context.alerts.find((alert) => alert.message === `${getI18nLocaleString(namespaceList.admin, "requiredFieldMissing")}: ${missingFields.join(",")}`)) {
            context.alerts.push({ color: "danger", message: `${getI18nLocaleString(namespaceList.admin, "requiredFieldMissing")}: ${missingFields.join(",")}` });
        }
        const currentFlow = await ContextUtil.getCurrentFlow(context);
        return (
            <GuestInterface
                currentFlow={currentFlow}
                options={widget.options}
                localContent={localContent}
                context={context}
                dynamicFormSpec={dynamicFormSpec}
                mandatoryFields={mandatoryFields}
                className={className}
            />
        );
    },
};

export async function getFormField(formId: string, context: CMSProvidedProperties) {
    const form: Form | null = await context.cmsApi.formApi.findById({ id: formId });
    const properties: Array<InputSpec<any, string>> = (
        await Promise.all(
            form!.elements
                .filter((formElement: CmsApiWidget) => formElement !== null)
                .map(
                    async (formElement: CmsApiWidget, index): Promise<any> => {
                        const elementWidget: Widget<any> = await parseApiWidget(formElement, context);
                        const spec = elementWidget.spec;
                        const TARGETS = ["form", "container", "reveal-link"];
                        if (isFormWidget(spec)) {
                            return spec.toInputSpec(elementWidget, context);
                        } else if (isPageWidget(spec)) {
                            if (spec.id === "flexbox") {
                                const children: JSX.Element[] = await getWidgetChildElements({ context, widget: elementWidget });
                                const styleClasses = findMultiSelectStyleClassNames(context.theme, TARGETS, elementWidget.options.styleIds);
                                return {
                                    spec,
                                    options: elementWidget.options,
                                    children,
                                    className: styleClasses,
                                    left: "<",
                                    right: ">",
                                    id: elementWidget._id,
                                } as any; // TODO: Figure out which type is returned here, add it if it doesn't exist
                            } else if (spec.id === "revealer") {
                                const children: JSX.Element[] = await getWidgetChildElements({ context, widget: elementWidget });
                                const localeContent = getLocalizedOptions(context, elementWidget.options);
                                const showLess = localeContent && localeContent.showLess ? localeContent.showLess.label : "";
                                const showMore = localeContent && localeContent.showMore ? localeContent.showMore.label : "";
                                const styleClasses = findMultiSelectStyleClassNames(context.theme, TARGETS, elementWidget.options.styleIds);
                                return {
                                    spec,
                                    id: elementWidget._id,
                                    childs: children,
                                    options: elementWidget.options,
                                    showLess,
                                    showMore,
                                    className: styleClasses,
                                } as any; // TODO: Figure out which type is returned here, add it if it doesn't exist
                            } else if (spec.id === "webcontent") {
                                return spec.render(elementWidget, context, undefined, undefined, undefined, true).catch((err) => {
                                    reportWidgetRenderError("guestInterfaceWidget", err, spec, context);
                                    return null;
                                });
                            }
                            throw new Error("Only Container widgets, webcontent or form widgets are allowed");
                        } else {
                            throw new Error("Form elemens should be form widgets");
                        }
                    }
                )
        )
    ).filter((prop) => !!prop);
    const dynamicFormSpec: FormSpec<any> = {
        id: formId,
        name: (form!.name as any) as string,
        pluralName: (form!.name as any) as string,
        properties: (properties as any) as Array<SomeInputSpec<any, string>>,
    };
    return dynamicFormSpec;
}

// Get the child widgets as JSX elements
// TODO: Try to get rid of the many any's
export const getWidgetChildElements = async ({ context, widget }: { context: CMSProvidedProperties; widget: Widget<any> }): Promise<JSX.Element[]> =>
    Promise.all(
        widget.children.map((child: Widget<any>, index) => {
            const childSpec = child.spec;
            if (isPageWidget(childSpec)) {
                return childSpec
                    .render(child, context)
                    .then((el) => ({ el, options: child.options }))
                    .catch((err) => {
                        reportWidgetRenderError(widget, err, childSpec, context);
                        return <div key={index} />;
                    });
            } else if (isFormWidget(childSpec)) {
                return (childSpec as any).toInputSpec(child, context);
            }
            throw new TypeError("Expected child widgets to be page widgets");
        })
    );
