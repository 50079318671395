import * as React from "react";
import * as classNames from "classnames";

import { CMSProvidedProperties, CMSProviderProperties } from "../../../containers/cmsProvider.types";
import { Localized, WidgetOptions } from "./";

import { CMSAware } from "../../../containers/CmsProvider";
import LocalizedTitleAndLabel from "../../../components/widgetTitleAndLabel/LocalizedLableTitle";
import { PageWidgetBaseProps } from "../pageWidget.types";
import { PermissionType } from "@maxxton/cms-mxts-api";
import { Widget } from "@maxxton/cms-api";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import loadable from "@loadable/component";
import { loadableRetry } from "../../../utils/loadableComponents.util";
import { setOpacityOnHide } from "../../../components/utils";
import { wrapProps } from "../../../i18n";

const DynamicForm = loadable(() => loadableRetry(() => import("./dynamicForm")), {
    resolveComponent: ({ DynamicForm }) => DynamicForm,
});

interface GuestInterfaceBaseProps extends PageWidgetBaseProps<WidgetOptions> {
    localContent: Localized | undefined;
    context: CMSProviderProperties;
    dynamicFormSpec: any;
    mandatoryFields: string[];
    className?: string;
    currentFlow?: Widget;
}

interface GuestInterfaceProps extends CMSProvidedProperties, GuestInterfaceBaseProps {}

interface GuestInterfaceState {}

class GuestInterfaceBase extends React.PureComponent<GuestInterfaceProps, GuestInterfaceState> {
    constructor(props: GuestInterfaceProps) {
        super(props);
        this.state = {};
    }

    public render(): JSX.Element | null {
        const { options, context, dynamicFormSpec, mandatoryFields, className, currentFlow } = this.props;
        const hideWidget = setOpacityOnHide(options);
        const { currentLocale, site } = context;
        const { enableWidgetTitle, useTitleHeadings, styleWidgetTitle, fontColor } = options;
        const localizedWidgetTitle: string = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedWidgetTitle || [], keys: ["widgetTitleText"] })?.widgetTitleText || "";
        return dynamicFormSpec ? (
            <div className={className}>
                <LocalizedTitleAndLabel
                    localizedTitle={localizedWidgetTitle}
                    enableWidgetTitle={enableWidgetTitle}
                    useTitleHeadings={useTitleHeadings}
                    styleWidgetTitle={styleWidgetTitle}
                    className={classNames("widget-heading", `${fontColor?.includes("theme") && `color-${fontColor}`}`)}
                    style={classNames(fontColor?.includes("rgba") && fontColor)}
                />
                <DynamicForm
                    permission={PermissionType.EXECUTE}
                    className={`${hideWidget}dynamic-form`}
                    spec={dynamicFormSpec}
                    value={{}}
                    currentFlow={currentFlow}
                    options={options}
                    alerts={context.alerts}
                    context={context}
                    mandatoryFields={mandatoryFields}
                    isGuestInterfaceWidget={true}
                />
            </div>
        ) : null;
    }
}

export const GuestInterface = wrapProps<GuestInterfaceBaseProps>(CMSAware<GuestInterfaceBaseProps>(GuestInterfaceBase));
